import * as React from 'react';

import { View } from '../components/view';

export const FourOhFourView: React.FC = () => {
  return (
    <View title="404">
      <h1>404</h1>
    </View>
  );
};

export default FourOhFourView;
